import { useLocation, Link } from 'react-router-dom';

const cityAddressesUA = {
    Dnipropetrovsk: 'Дніпро вул.Калинова буд 40',
    'Dnipropetrovsk Oblast': 'Дніпро вул.Калинова буд 40',
    Kyiv: 'Київ ул.Дарвіна буд 8',
    'Kyiv City': 'Київ ул.Дарвіна буд 8',
    'Kyiv Oblast': 'Київ ул.Дарвіна буд 8',
    Cherkasy: 'Черкаси вул.Вернигорі буд 9',
    'Cherkasy Oblast': 'Черкаси вул.Вернигорі буд 9',
    Sumy: 'Суми вул.Герасима Кондратьєва буд 21',
    'Sumy Oblast': 'Суми вул.Герасима Кондратьєва буд 21',
    Ternopil: 'Тернопіль вул.Князя Острозького буд 3',
    'Ternopil Oblast': 'Тернопіль вул.Князя Острозького буд 3',
    Kharkiv: 'Харків пр. Науки, буд 56',
    'Kharkiv Oblast': 'Харків пр. Науки, буд 56',
    Poltava: 'Полтава вул.Театральна буд 10',
    'Poltava Oblast': 'Полтава вул.Театральна буд 10',
    Chernihiv: 'Чернігів вул.Ринкова буд 12',
    'Chernihiv Oblast': 'Чернігів вул.Ринкова буд 12',
    Khmelnytskyi: "Хмельницький вул.Кам'янецька буд 39",
    'Khmelnytskyi Oblast': "Хмельницький вул.Кам'янецька буд 39",
    Mykolaiv: 'Миколаїв вул.Третя Слобіцька буд 12',
    'Mykolaiv Oblast': 'Миколаїв вул.Третя Слобіцька буд 12',
    Odesa: 'Одесса вул.Мічуріна буд 15',
    'Odesa Oblast': 'Одесса вул.Мічуріна буд 15',
    Vinnytsia: 'Вінниця вул.Академіка Янгеля буд 50',
    'Vinnytsia Oblast': 'Вінниця вул.Академіка Янгеля буд 50',
    Zaporizhzhia: 'Запоріжжя пр. Соборний буд 143',
    'Zaporizhzhia Oblast': 'Запоріжжя пр. Соборний буд 143',
    Zhytomyr: 'Житомир вул.Вітрука буд 10',
    'Zhytomyr Oblast': 'Житомир вул.Вітрука буд 10',
};

const cityAddressesRU = {
    Dnipropetrovsk: 'Днепр ул.Калинова д.40',
    'Dnipropetrovsk Oblast': 'Днепр ул.Калинова д.40',
    Kyiv: 'Киев ул.Дарвина д.8',
    'Kyiv City': 'Киев ул.Дарвина д.8',
    'Kyiv Oblast': 'Киев ул.Дарвина д.8',
    Cherkasy: 'Черкассы ул.Вернигори д.9',
    'Cherkasy Oblast': 'Черкассы ул.Вернигори д.9',
    Sumy: 'Сумы ул.Герасима Кондратьева д.21',
    'Sumy Oblast': 'Сумы ул.Герасима Кондратьева д.21',
    Ternopil: 'Тернополь ул.Князя Острожского д.3',
    'Ternopil Oblast': 'Тернополь ул.Князя Острожского д.3',
    Kharkiv: 'Харьков пр. Науки, д.56',
    'Kharkiv Oblast': 'Харьков пр. Науки, д.56',
    Poltava: 'Полтава ул.Театральная д.10',
    'Poltava Oblast': 'Полтава ул.Театральный д.10',
    Chernihiv: 'Чернигов ул.Рыночная д.12',
    'Chernihiv Oblast': 'Чернигов ул.Рыночная д.12',
    Khmelnytskyi: 'Хмельницкий ул.Каменецкая д.39',
    'Khmelnytskyi Область': 'Хмельницкий ул.Каменецкая д.39',
    Mykolaiv: 'Николаев ул.Третья Слобицкая д.12',
    'Mykolaiv Oblast': 'Николаев ул.Третья Слобицкая д.12',
    Odesa: 'Одесса ул.Мичурина д.15',
    'Odesa Oblast': 'Одесса ул.Мичурина д.15',
    Vinnytsia: 'Винница ул.Академика Янгеля д.50',
    'Vinnytsia Oblast': 'Винница ул.Академика Янгеля д.50',
    Zaporizhzhia: 'Запорожье пр. Соборный д.143',
    'Zaporizhzhia Oblast': 'Запорожье пр. Соборный д.143',
    Zhytomyr: 'Житомир ул.Витрука д.10',
    'Zhytomyr Oblast': 'Житомир ул.Ветрука д.10',
};

export const Contacts = ({ CONTACTS, locationInfo }) => {
    const { pathname } = useLocation();
    const Language = (ua, ru) => (pathname === '/' ? ua : ru);
    const findFiel = (fieldId) => CONTACTS?.fields.find((el) => el._id === fieldId);

    const TITLE = findFiel('652d17a5ba63b8929f4c1c38');
    const PHONES = findFiel('652d1804ba63b8929f4c1c3a');
    const MAIL = findFiel('652d1823ba63b8929f4c1c3c');
    const WORK_TIME = findFiel('652d1852ba63b8929f4c1c3e');
    const ADDRESS = findFiel('652d18caba63b8929f4c1c40');

    const userAddress = Language(
        cityAddressesUA[locationInfo] || 'Київ ул.Дарвіна буд 8',
        cityAddressesRU[locationInfo] || 'Киев ул.Дарвина д.8',
    );

    return (
        <section className="contacts" id="contacts">
            <div className="container container-small">
                <h2 className="subtitle">
                    {Language(TITLE.content.UA.text, TITLE.content.RU.text)}
                </h2>
                <div className="items">
                    <div className="item">
                        <div className="name">
                            {Language(
                                PHONES.content.UA.list[0]?.item,
                                PHONES.content.RU.list[0]?.item,
                            )}
                        </div>
                        <div className="content">
                            <div className="icon icon-phone"></div>
                            <div>
                                <div className="text" itemProp="telephone">
                                    <Link
                                        to={`tel:${Language(
                                            PHONES.content.UA.list[1]?.item,
                                            PHONES.content.RU.list[1]?.item,
                                        )}`}>
                                        {' '}
                                        {Language(
                                            PHONES.content.UA.list[1]?.item,
                                            PHONES.content.RU.list[1]?.item,
                                        )}
                                    </Link>
                                </div>
                                <div className="text" itemProp="telephone">
                                    <Link
                                        to={`tel:${Language(
                                            PHONES.content.UA.list[2]?.item,
                                            PHONES.content.RU.list[2]?.item,
                                        )}`}>
                                        {' '}
                                        {Language(
                                            PHONES.content.UA.list[2]?.item,
                                            PHONES.content.RU.list[2]?.item,
                                        )}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="name">
                            {Language(MAIL.content.UA.list[0]?.item, MAIL.content.RU.list[0]?.item)}
                        </div>
                        <div className="content">
                            <div className="icon icon-mail"></div>
                            <div>
                                <div className="text" itemProp="email">
                                    <Link
                                        to={`mailto:${Language(
                                            MAIL.content.UA.list[1]?.item,
                                            MAIL.content.RU.list[1]?.item,
                                        )}`}>
                                        {Language(
                                            MAIL.content.UA.list[1]?.item,
                                            MAIL.content.RU.list[1]?.item,
                                        )}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="name">
                            {Language(
                                WORK_TIME.content.UA.list[0]?.item,
                                WORK_TIME.content.RU.list[0]?.item,
                            )}
                        </div>
                        <div className="content">
                            <div className="icon icon-time"></div>
                            <div>
                                <div className="text">
                                    {Language(
                                        WORK_TIME.content.UA.list[1]?.item,
                                        WORK_TIME.content.RU.list[1]?.item,
                                    )}
                                </div>
                                <div className="text">
                                    {Language(
                                        WORK_TIME.content.UA.list[2]?.item,
                                        WORK_TIME.content.RU.list[2]?.item,
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="name">
                            {Language(ADDRESS.content.UA?.text, ADDRESS.content.RU?.text)}
                        </div>
                        <div className="content">
                            <div className="icon icon-placeholder"></div>
                            <div>
                                <div className="text">
                                    <span> {userAddress}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
