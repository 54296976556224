import { useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';

export const Reviews = ({ REVIEWS }) => {
    const { pathname } = useLocation();
    const Language = (ua, ru) => (pathname === '/' ? ua : ru);
    const findFiel = (fieldId) => REVIEWS?.fields.find((el) => el._id === fieldId);

    useEffect(() => {
        // eslint-disable-next-line no-undef
        $('#carousel-TWY15').owlCarousel({
            items: 1,
            loop: true,
            dots: true,
            dotsContainer: '#carousel-dots-TWY15',
            responsive: {
                0: {
                    nav: false,
                },
                960: {
                    nav: true,
                    navText: [
                        "<div class='prev icon icon-back'></div>",
                        "<div class='next icon icon-forward'></div>",
                    ],
                    navContainer: '#carousel-nav-TWY15',
                },
            },
        });
    }, []);

    const TITLE = findFiel('652d045bba63b8929f4c1bd4');
    const DESCRIPTION = findFiel('652d0482ba63b8929f4c1bd6');
    const BUTTON_INSTAGRAM = findFiel('652d08e5ba63b8929f4c1beb');
    const BUTTON_FALCEBOOK = findFiel('652d08efba63b8929f4c1bed');

    return (
        <section className="reviews" id="reviews">
            <div className="container">
                <div className="content">
                    <div className="text">
                        <h2 className="subtitle">
                            {Language(TITLE.content.UA.text, TITLE.content.RU.text)}
                        </h2>
                        <div
                            className="desc"
                            dangerouslySetInnerHTML={{
                                __html: Language(
                                    DESCRIPTION?.content.UA.rich_text,
                                    DESCRIPTION?.content.RU.rich_text,
                                ),
                            }}
                        />
                        <Link
                            to={Language(
                                BUTTON_INSTAGRAM.content.UA.link,
                                BUTTON_INSTAGRAM.content.RU.link,
                            )}
                            className="reviews-popup-btn btn mr-10">
                            {Language(
                                BUTTON_INSTAGRAM.content.UA.title,
                                BUTTON_INSTAGRAM.content.RU.title,
                            )}
                        </Link>
                        <Link
                            to={Language(
                                BUTTON_FALCEBOOK.content.UA.link,
                                BUTTON_FALCEBOOK.content.RU.link,
                            )}
                            className="reviews-popup-btn btn">
                            {' '}
                            {Language(
                                BUTTON_FALCEBOOK.content.UA.title,
                                BUTTON_FALCEBOOK.content.RU.title,
                            )}
                        </Link>
                    </div>

                    <div className="slider">
                        <div
                            className="owl-carousel owl-theme carousel owl-loaded owl-drag"
                            id="carousel-TWY15">
                            {REVIEWS.fields.map(
                                (el, ind) =>
                                    el.field_type === 'Testimonial' && (
                                        <div key={ind} className="owl-item">
                                            <div className="item">
                                                <div className="name">
                                                    {Language(
                                                        el.content.UA.fullname,
                                                        el.content.RU.fullname,
                                                    )}
                                                </div>
                                                <div
                                                    className="review"
                                                    dangerouslySetInnerHTML={{
                                                        __html: Language(
                                                            el?.content.UA.comment,
                                                            el?.content.RU.comment,
                                                        ),
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    ),
                            )}
                        </div>
                        <div className="owl-nav" id="carousel-nav-TWY15"></div>
                        <div className="owl-dots" id="carousel-dots-TWY15"></div>
                    </div>
                </div>
            </div>
        </section>
    );
};
