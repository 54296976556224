import { useEffect, useState } from 'react';
import CountUp from 'react-countup';
import { useLocation } from 'react-router-dom';

export const Company = ({ COMPANY }) => {
    const { pathname } = useLocation();
    const Language = (ua, ru) => (pathname === '/' ? ua : ru);
    const findFiel = (fieldId) => COMPANY?.fields.find((el) => el._id === fieldId);

    const IMAGE = findFiel('652d106fba63b8929f4c1c09');
    const TITLE = findFiel('652d101bba63b8929f4c1c02');
    const DESCRIPTION = findFiel('652d104aba63b8929f4c1c04');
    const Text_1 = findFiel('652d11e5ba63b8929f4c1c0c');
    const Text_2 = findFiel('652d120bba63b8929f4c1c0e');
    const Text_3 = findFiel('652d1249ba63b8929f4c1c10');
    const Text_4 = findFiel('652d1278ba63b8929f4c1c12');

    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const companyElement = document.getElementById('company');
        if (!companyElement) return;

        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.disconnect();
                }
            },
            { threshold: 0.5 },
        );

        observer.observe(companyElement);
    }, []);

    return (
        <section className="company" id="company">
            <div className="container container-small">
                <div className="content">
                    <div className="image">
                        <img
                            src={IMAGE.content.images[0].path}
                            alt={IMAGE.content.images[0].originalname}
                        />
                    </div>
                    <div className="text">
                        <h2 className="subtitle">
                            {Language(TITLE.content.UA.text, TITLE.content.RU.text)}
                        </h2>
                        <div
                            className="description"
                            dangerouslySetInnerHTML={{
                                __html: Language(
                                    DESCRIPTION?.content.UA.rich_text,
                                    DESCRIPTION?.content.RU.rich_text,
                                ),
                            }}
                        />
                        <div className="counters" id="company-counts">
                            <div className="item">
                                <div className="number spincrement">
                                    {isVisible && (
                                        <CountUp
                                            start={0}
                                            end={Number(Text_1.content.UA.text.split('|')[1])}
                                            duration={5}
                                            separator=" "
                                        />
                                    )}
                                </div>
                                <div className="desc">
                                    {
                                        Language(
                                            Text_1.content.UA.text,
                                            Text_1.content.RU.text,
                                        ).split('|')[0]
                                    }
                                </div>
                            </div>
                            <div className="item">
                                <div className="number spincrement">
                                    {isVisible && (
                                        <CountUp
                                            start={0}
                                            end={Number(
                                                Text_2.content.UA.text.split('|')[1].slice(0, -1),
                                            )}
                                            duration={5}
                                            separator=" "
                                        />
                                    )}
                                    +
                                </div>
                                <div className="desc">
                                    {
                                        Language(
                                            Text_2.content.UA.text,
                                            Text_2.content.RU.text,
                                        ).split('|')[0]
                                    }
                                </div>
                            </div>
                            <div className="item">
                                <div className="number spincrement">
                                    {isVisible && (
                                        <CountUp
                                            start={0}
                                            end={Number(Text_3.content.UA.text.split('|')[1])}
                                            duration={10}
                                            separator=""
                                        />
                                    )}
                                </div>
                                <div className="desc">
                                    {
                                        Language(
                                            Text_3.content.UA.text,
                                            Text_3.content.RU.text,
                                        ).split('|')[0]
                                    }
                                </div>
                            </div>
                            <div className="item">
                                <div className="number spincrement">
                                    {isVisible && (
                                        <CountUp
                                            start={0}
                                            end={Number(
                                                Text_4.content.UA.text.split('|')[1].slice(0, -1),
                                            )}
                                            duration={15}
                                            separator=""
                                        />
                                    )}
                                    %
                                </div>
                                <div className="desc">
                                    {
                                        Language(
                                            Text_4.content.UA.text,
                                            Text_4.content.RU.text,
                                        ).split('|')[0]
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
