import { Link, useLocation } from 'react-router-dom';
import Modal from 'react-modal';
import { useState } from 'react';
import axios from 'axios';
import TextMaskInput from 'react-text-mask';

const customStyles = {
    content: {
        height: 'auto ',
    },
};

export const CompliteOrderModal = ({ isOpen, closeModal, selectedProduct }) => {
    const { pathname } = useLocation();
    const Language = (ua, ru) => (pathname === '/' ? ua : ru);
    const [isLoading, setLoading] = useState();
    const [errors, setErrors] = useState({});
    const [isChecked, setChecked] = useState(false);
    const [isSuccesMessage, setSuccesMessage] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
    });

    const validateName = (value) => {
        if (value.trim() === '') {
            return "Це поле є обов'язковим";
        }
        return '';
    };

    const validatePhone = (value) => {
        const phonePattern = /^\+38\s\d{3}\s\d{3}\s\d{2}\s\d{2}$/;

        if (!phonePattern.test(value)) {
            return 'Введіть правильний номер телефону';
        }
        return '';
    };

    const fetchCreate = async () => {
        setLoading(true);

        // Виконайте валідацію перед відправкою форми
        const newErrors = { ...errors };
        newErrors.name = validateName(formData.name);
        newErrors.phone = validatePhone(formData.phone);

        // Якщо є помилки, не відправляйте форму
        if (newErrors.name || newErrors.phone) {
            setErrors(newErrors);
            setLoading(false);
        } else {
            setErrors(newErrors);
            try {
                const { data } = await axios.post('https://backend.server-zanaves.com.ua/api/order', {
                    fullName: formData.name,
                    phone: formData.phone,
                    service: 'Вибір тканини',
                    whence: 'landing',
                    status: '650ae621217b8af46970f7cf', // Новий
                    note: `Тканині ролети: ${selectedProduct.name}, Артикул: ${selectedProduct?.image?.originalname || '----'}, ${
                        isChecked ? 'ПОТРІБЕН ЗАМІР' : ''
                    }`,
                });

                if (data.success) {
                    setLoading(false);
                    setSuccesMessage(true);
                    setFormData({
                        name: '',
                        phone: '',
                    });
                } else {
                    setLoading(false);
                }
            } catch (e) {
                setLoading(false);
                return;
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        setErrors({});
    };

    const onClose = () => {
        closeModal();
        setSuccesMessage(false);
        setChecked(false);
        setFormData({
            name: '',
            phone: '',
        });
        setErrors({});
    };

    if (!isOpen) return null;
    return (
        <Modal isOpen={isOpen} onRequestClose={onClose} style={customStyles} contentLabel="Модальне вікно">
            <div className="callback-popup popup jqm-init open">
                <div className="wrap">
                    <div className="head">
                        <div className="title">
                            {!isSuccesMessage ? (
                                <>
                                    <span>{Language('Ваше замовлення', 'Ваш заказ')}:</span>
                                    <span className="text_3">
                                        {Language('Тканині ролети', 'Тканевые роллеты')}:{' '}
                                        <strong className="color_orange">{selectedProduct.name}</strong>
                                    </span>
                                    {!!selectedProduct?.image && (
                                        <span className="text_3">
                                            {selectedProduct?.image?.originalname !== '' && 'Артикул: '}
                                            <strong className="color_orange">{selectedProduct?.image?.originalname}</strong>
                                        </span>
                                    )}
                                </>
                            ) : (
                                <span>{Language('Дякуємо', 'Спасибо')} </span>
                            )}
                        </div>
                        <Link onClick={closeModal} className="close"></Link>
                    </div>

                    <div className="form form-popup">
                        {!isSuccesMessage ? (
                            <>
                                <div className="fields">
                                    <div className="fields_text">
                                        {Language(
                                            'Всю додаткову інформацію про замовлення уточнить наш менеджер.',
                                            'Всю дополнительную информацию о заказе уточнит наш менеджер.',
                                        )}
                                    </div>
                                    <div className="field text">
                                        <div className="label">
                                            {Language('Ваше ім’я', 'Ваше имя')}
                                            <sup>*</sup>
                                        </div>
                                        <input
                                            type="text"
                                            name="name"
                                            required="required"
                                            style={{ borderColor: errors.name && '#ff0000' }}
                                            value={formData.name}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="field text">
                                        <div className="label">
                                            {Language('Ваш номер телефона', 'Ваш номер телефона')}
                                            <sup>*</sup>
                                        </div>
                                        <TextMaskInput
                                            type="tel"
                                            name="phone"
                                            value={formData.phone}
                                            style={{ borderColor: errors.phone && '#ff0000' }}
                                            onChange={handleInputChange}
                                            mask={[
                                                '+',
                                                '3',
                                                '8',
                                                ' ',
                                                /[0-9]/,
                                                /[0-9]/,
                                                /[0-9]/,
                                                ' ',
                                                /[0-9]/,
                                                /[0-9]/,
                                                /[0-9]/,
                                                ' ',
                                                /[0-9]/,
                                                /[0-9]/,
                                                ' ',
                                                /[0-9]/,
                                                /[0-9]/,
                                            ]}
                                        />
                                    </div>
                                    <div className="field personal checkbox">
                                        <input type="checkbox" checked={isChecked} />
                                        <label onClick={() => setChecked(!isChecked)}>{Language('МЕНІ ПОТРІБЕН ЗАМІР', 'МНЕ НУЖЕН ЗАМЕР')}</label>
                                    </div>
                                </div>
                                <div className="modal_footer">
                                    <button onClick={fetchCreate} type="submit" disabled={isLoading} className="btn color submit">
                                        {isLoading ? 'Loading...' : Language('Відправити', 'Отправить')}
                                    </button>
                                    <div className="modal_footer__text">
                                        {Language(
                                            'Після підрахунку вартості та узгодження деталій замовлення, ми надішлемо рахунок для оплати.',
                                            'После подсчета стоимости и согласования деталей заказа, мы пришлем счет для оплаты.',
                                        )}
                                    </div>
                                </div>
                            </>
                        ) : (
                            <div className="content">
                                <div className="icon icon-succees"></div>
                                <div className="text">{Language("Наш менеджер скоро з вами зв'яжеться", 'Наш менеджер скоро с вами свяжется')}</div>
                                <Link onClick={onClose} className="btn color">
                                    {Language('Закрити', 'Закрыть')}
                                </Link>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Modal>
    );
};
