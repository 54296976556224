import { Link, useLocation } from 'react-router-dom';
import { ZamerModal } from '../Modals/ZamerModal';
import { useState } from 'react';

export const Footer = ({ FOOTER }) => {
    const { pathname } = useLocation();
    const Language = (ua, ru) => (pathname === '/' ? ua : ru);
    const findFiel = (fieldId) => FOOTER?.fields.find((el) => el._id === fieldId);

    const [isModalOpen, setModalOpen] = useState(false);
    const openModal = () => {
        setModalOpen(true);
    };
    const closeModal = () => {
        setModalOpen(false);
    };

    const LOGO = findFiel('652d6254ba63b8929f4c1cf9');
    const SOCIAL_LINKS = findFiel('652d62d7ba63b8929f4c1cfb');
    const PHONE = findFiel('652d62f5ba63b8929f4c1cfd');
    const BUTTON_TEXT = findFiel('652d630dba63b8929f4c1cff');
    const WORK_YEARS = findFiel('652d6346ba63b8929f4c1d01');
    const COPYRIGHT = findFiel('652d6360ba63b8929f4c1d03');
    const POLITICA_LINKS = findFiel('652d6377ba63b8929f4c1d05');
    const PAYMENTS_TEXT = findFiel('652d63abba63b8929f4c1d07');

    return (
        <footer>
            <ZamerModal isOpen={isModalOpen} closeModal={closeModal} />
            <div className="container">
                <div className="links">
                    <div className="logo">
                        <img
                            src={LOGO.content.images[0]?.path}
                            alt={LOGO.content.images[0]?.originalname}
                        />
                    </div>
                    <div className="social">
                        <Link
                            to={Language(
                                SOCIAL_LINKS.content.UA.links[0].link,
                                SOCIAL_LINKS.content.RU.links[0].link,
                            )}
                            className="link icon iconl-paper-plane"
                            target="_blank"
                        />
                        <Link
                            to={Language(
                                SOCIAL_LINKS.content.UA.links[1].link,
                                SOCIAL_LINKS.content.RU.links[1].link,
                            )}
                            className="link icon iconl-facebook"
                            target="_blank"
                        />
                        <Link
                            to={Language(
                                SOCIAL_LINKS.content.UA.links[2].link,
                                SOCIAL_LINKS.content.RU.links[2].link,
                            )}
                            className="link icon iconl-instagram"
                            target="_blank"
                        />
                        <Link
                            to={Language(
                                SOCIAL_LINKS.content.UA.links[3].link,
                                SOCIAL_LINKS.content.RU.links[3].link,
                            )}
                            className="link icon iconl-whatsapp"
                            target="_blank"
                        />
                    </div>
                    <div className="tel">
                        <div className="content">
                            <div className="item">
                                <a
                                    href={`tel:${Language(
                                        PHONE.content.UA.text,
                                        PHONE.content.RU.text,
                                    )}`}>
                                    {Language(PHONE.content.UA.text, PHONE.content.RU.text)}
                                </a>
                            </div>
                            <div className="item"></div>
                        </div>
                    </div>
                    <button onClick={openModal} className="btn callback-popup-btn transparent">
                        {Language(BUTTON_TEXT.content.UA.title, BUTTON_TEXT.content.RU.title)}
                    </button>
                </div>
                <div className="info">
                    <div className="copyright">
                        <div className="year">
                            {Language(WORK_YEARS.content.UA.text, WORK_YEARS.content.RU.text)}
                        </div>
                        <div className="text">
                            {Language(COPYRIGHT.content.UA.text, COPYRIGHT.content.RU.text)}
                        </div>
                    </div>
                    <Link
                        to={Language(
                            POLITICA_LINKS.content.UA.links[0].link,
                            POLITICA_LINKS.content.RU.links[0].link,
                        )}
                        className="agreement-popup-btn policy">
                        {Language(
                            POLITICA_LINKS.content.UA.links[0].title,
                            POLITICA_LINKS.content.RU.links[0].title,
                        )}
                    </Link>
                    <div className="payments">
                        <div className="text">
                            {Language(PAYMENTS_TEXT.content.UA.text, PAYMENTS_TEXT.content.RU.text)}
                        </div>
                        <div className="type mastercard"></div>
                        <div className="type visa"></div>
                        <div className="type yandex"></div>
                        <div className="type webmoney"></div>
                        <div className="type qiwi"></div>
                    </div>
                </div>
            </div>
        </footer>
    );
};
