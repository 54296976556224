import { useLocation } from 'react-router-dom';

export const Advantages = ({ ADVANTAGES }) => {
    const { pathname } = useLocation();
    const Language = (ua, ru) => (pathname === '/' ? ua : ru);
    const findFiel = (fieldId) => ADVANTAGES?.fields.find((el) => el._id === fieldId);

    const TITLE = findFiel('652be58aba63b8929f4c1ae7');
    const DESCRIPTION = findFiel('652be5b9ba63b8929f4c1ae9');
    const BLOCK_1 = findFiel('652be620ba63b8929f4c1aeb');
    const BLOCK_2 = findFiel('652be6abba63b8929f4c1aed');
    const BLOCK_3 = findFiel('652be7acba63b8929f4c1af9');
    const BLOCK_4 = findFiel('652be7d5ba63b8929f4c1afb');

    return (
        <section className="pros" id="advantages">
            <div className="container">
                <div className="content">
                    <div className="text">
                        <h2 className="subtitle white">
                            {Language(TITLE.content.UA.text, TITLE.content.RU.text)}
                        </h2>
                        <div
                            className="desc"
                            dangerouslySetInnerHTML={{
                                __html: Language(
                                    DESCRIPTION?.content.UA.rich_text,
                                    DESCRIPTION?.content.RU.rich_text,
                                ),
                            }}
                        />
                    </div>

                    <div className="items top">
                        <div className="item">
                            <img
                                src="./img/icons/icon1-1.svg"
                                className="item_icon_svg mr-10"
                                alt=""
                            />
                            <div className="name">
                                {Language(BLOCK_1.content.UA.title, BLOCK_1.content.RU.title)}
                            </div>
                            <div
                                className="desc"
                                dangerouslySetInnerHTML={{
                                    __html: Language(
                                        BLOCK_1.content.UA.description,
                                        BLOCK_1.content.UA.description,
                                    ),
                                }}
                            />
                        </div>
                        <div className="item">
                            <img
                                src="./img/icons/icon2-2.svg"
                                className="item_icon_svg mr-10"
                                alt=""
                            />
                            <div className="name">
                                {Language(BLOCK_2.content.UA.title, BLOCK_2.content.RU.title)}
                            </div>
                            <div
                                className="desc"
                                dangerouslySetInnerHTML={{
                                    __html: Language(
                                        BLOCK_2.content.UA.description,
                                        BLOCK_2.content.UA.description,
                                    ),
                                }}
                            />
                        </div>
                        <div className="item">
                            <img
                                src="./img/icons/icon3.svg"
                                className="item_icon_svg mr-10"
                                alt=""
                            />
                            <div className="name">
                                {Language(BLOCK_3.content.UA.title, BLOCK_3.content.RU.title)}
                            </div>
                            <div
                                className="desc"
                                dangerouslySetInnerHTML={{
                                    __html: Language(
                                        BLOCK_3.content.UA.description,
                                        BLOCK_3.content.UA.description,
                                    ),
                                }}
                            />
                        </div>
                        <div className="item">
                            <img
                                src="./img/icons/icon4.svg"
                                className="item_icon_svg mr-10"
                                alt=""
                            />
                            <div className="name">
                                {Language(BLOCK_4.content.UA.title, BLOCK_4.content.RU.title)}
                            </div>
                            <div
                                className="desc"
                                dangerouslySetInnerHTML={{
                                    __html: Language(
                                        BLOCK_4.content.UA.description,
                                        BLOCK_4.content.UA.description,
                                    ),
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
