import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ZamerModal } from '../Modals/ZamerModal';

export const FabricCatalog = ({ FABRICCATALOG }) => {
    const { pathname } = useLocation();
    const Language = (ua, ru) => (pathname === '/' ? ua : ru);
    const findFiel = (fieldId) => FABRICCATALOG?.fields.find((el) => el._id === fieldId);

    const [isModalOpen, setModalOpen] = useState(false);
    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);

    const IMAGE = findFiel('652d59b3ba63b8929f4c1cdb');
    const TITLE = findFiel('652d59d4ba63b8929f4c1cdd');
    const DESCRIPTION = findFiel('652d59ebba63b8929f4c1cdf');
    const BUTTON = findFiel('652d5a05ba63b8929f4c1ce1');

    return (
        <section className="price-list price-list--zamer" id="fabric_catalog">
            <ZamerModal isOpen={isModalOpen} closeModal={closeModal} />
            <div className="container">
                <div className="content content--reverse">
                    <div className="info info--zamer">
                        <img
                            src={IMAGE.content.images[0]?.path}
                            className="price-list_img price-list_img--relative"
                            alt={IMAGE.content.images[0]?.originalname}
                        />
                    </div>

                    <div className="fields price_info price_info--zamer">
                        <h2 className="subtitle white price-list_title price-list_title--zamer">
                            {Language(TITLE.content.UA.text, TITLE.content.RU.text)}
                        </h2>
                        <p
                            className="price-list_p_text"
                            dangerouslySetInnerHTML={{
                                __html: Language(
                                    DESCRIPTION?.content.UA.rich_text,
                                    DESCRIPTION?.content.RU.rich_text,
                                ),
                            }}
                        />

                        <div className="price-list_btn_box">
                            <button
                                onClick={openModal}
                                type="submit"
                                className="btn color submit price_btn">
                                {Language(BUTTON.content.UA.title, BUTTON.content.RU.title)}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
