import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ZamerModal } from '../Modals/ZamerModal';
import { DiznatusVartistModal } from '../Modals/DiznatusVartistModal';
import LazyLoad from 'react-lazyload';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export const Header = ({ HEADER, HEADER_SLIDER, HEADER_ADVANTAGES }) => {
    const { pathname } = useLocation();
    const [scrollY, setScrollY] = useState('');

    const [isModalOpen, setModalOpen] = useState(false);
    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);

    const [isModalOpenVartist, setModalOpenVartist] = useState(false);
    const openModalVartist = () => setModalOpenVartist(true);
    const closeModalVartist = () => setModalOpenVartist(false);

    const findFieldHeader = (fieldId) => HEADER?.fields.find((el) => el._id === fieldId);
    const findFieldHeaderAdvantages = (fieldId) => HEADER_ADVANTAGES?.fields.find((el) => el._id === fieldId);
    const Language = (ua, ru) => (pathname === '/' ? ua : ru);

    window.onscroll = () => {
        if (window.scrollY > 70) {
            setScrollY('fixed');
        } else {
            setScrollY('');
        }
    };

    useEffect(() => {
        // eslint-disable-next-line no-undef
        $('#carousel-6HQZl').owlCarousel({
            items: 1,
            loop: true,
            dots: true,
            autoplay: true,
            autoplayTimeout: 3000,
            autoplayHoverPause: true,
            navText: ["<div class='prev icon icon-back'></div>", "<div class='next icon icon-forward'></div>"],
            dotsContainer: '#carousel-dots-6HQZl',
            navContainer: '#carousel-nav-6HQZl',
            responsive: {
                0: {
                    nav: false,
                },
                710: {
                    nav: false,
                },
                960: {
                    navSpeed: 500,
                    nav: true,
                },
                1211: {
                    navSpeed: false,
                    nav: true,
                },
            },
        });
    }, []);

    const Logo = findFieldHeader('65294f6714410a5819b54d2e');
    const Slogan = findFieldHeader('6529533cff6dc2a640face75');
    const Phone = findFieldHeader('65298bdfba63b8929f4c186c');

    // ====== advantages =====
    const text_1 = findFieldHeaderAdvantages('65299632ba63b8929f4c18d5');
    const text_2 = findFieldHeaderAdvantages('65299670ba63b8929f4c18d7');
    const text_3 = findFieldHeaderAdvantages('65299683ba63b8929f4c18d9');
    const text_4 = findFieldHeaderAdvantages('6529969bba63b8929f4c18db');
    const text_5 = findFieldHeaderAdvantages('652996b5ba63b8929f4c18dd');
    // ./====== advantages =====

    return (
        <header>
            <ZamerModal isOpen={isModalOpen} closeModal={closeModal} />
            <DiznatusVartistModal isOpen={isModalOpenVartist} closeModal={closeModalVartist} />
            <div className={`header ${scrollY}`}>
                <div className="container">
                    <div className="items">
                        <div className="logo">
                            <img src={Logo.content.images[0].path} alt="logo" />
                        </div>
                        <div className="slogan">{Language(Slogan?.content.UA.text, Slogan?.content.RU.text)}</div>
                        <div className="tel">
                            <div className="content">
                                <div className="item">
                                    <a className="header__tel" href={`tel:${Language(Phone?.content.UA.text, Phone?.content.RU.text)}`}>
                                        {Language(Phone?.content.UA.text, Phone?.content.RU.text)}
                                    </a>
                                </div>
                                <div className="item"></div>
                            </div>
                        </div>
                        <Link onClick={() => openModal(true)} className="btn callback-popup-btn transparent header_btn">
                            {pathname === '/' ? 'Замовити замір' : 'Заказать замер'}
                        </Link>

                        <Link to={pathname === '/' ? '/ru' : '/'} className="btn callback-popup-btn transparent local_button header_btn">
                            {pathname === '/' ? 'UA' : 'RU'}
                        </Link>
                    </div>
                </div>
            </div>

            {/* <!-- Великий слайдер в шапкі --> */}
            <div className="top-slider">
                <div className="owl-carousel" id="carousel-6HQZl">
                    {HEADER_SLIDER?.fields.map((slider, ind) => {
                        const SliderLink = slider.content?.link;
                        const SliderModal = slider.content?.modal;

                        const ZamerM = SliderModal === 'ZamerModal' && openModal;
                        const DiznatusVartistM = SliderModal === 'DiznatusVartistModal' && openModalVartist;

                        return (
                            <div key={ind} className="owl-item owl-item__slider_header">
                                <a href={SliderLink} onClick={ZamerM || DiznatusVartistM || void 0} className="item">
                                    <div className="gradient">
                                        <div className="container">
                                            <div className="content slider_show_big">
                                                {/* <LazyLoadImage
                                                    className="slider_show_big_image"
                                                    alt=""
                                                    height={310}
                                                    placeholderSrc="./img/placeholder_slider.jpeg"
                                                    src={slider?.content?.image_desktop[0]?.path}
                                                /> */}
                                                {/* <LazyLoad height={310} placeholder="./img/placeholder_slider.jpeg"> */}
                                                <img className="slider_show_big_image" src={slider?.content?.image_desktop[0]?.path} alt="" />
                                                {/* </LazyLoad> */}
                                            </div>
                                            <div className="content slider_show_small">
                                                <img className="slider_show_big_image" src={slider?.content?.image_mobile[0]?.path} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        );
                    })}
                </div>
                <div className="container">
                    <div className="owl-nav" id="carousel-nav-6HQZl"></div>
                    <div className="owl-dots top" id="carousel-dots-6HQZl"></div>
                </div>
            </div>
            {/* <!-- ./Великий слайдер в шапкі --> */}

            <div className="advantages">
                <div className="container">
                    <div className="items">
                        <div className="item">
                            <img src="./img/icons/consultation-icon.svg" className="item_icon_svg" alt="" />
                            <div className="text">{Language(text_1.content.UA.text, text_1.content.RU.text)}</div>
                        </div>
                        <div className="item">
                            <img src="./img/icons/working-icon.svg" className="item_icon_svg" alt="" />
                            <div className="text">{Language(text_2.content.UA.text, text_2.content.RU.text)}</div>
                        </div>
                        <div className="item">
                            <img src="./img/icons/documented-icon.svg" className="item_icon_svg" alt="" />
                            <div className="text">{Language(text_3.content.UA.text, text_3.content.RU.text)}</div>
                        </div>
                        <div className="item">
                            <img src="./img/icons/production-icon.svg" className="item_icon_svg" alt="" />

                            <div className="text">{Language(text_4.content.UA.text, text_4.content.RU.text)}</div>
                        </div>
                        <div className="item">
                            <img src="./img/icons/warranty-icon.svg" className="item_icon_svg" alt="" />
                            <div className="text">{Language(text_5.content.UA.text, text_5.content.RU.text)}</div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};
