export const Brands = ({ BRANDS }) => {
    return (
        <div className="brands" id="brands">
            <div className="container">
                <div className="items">
                    {BRANDS.fields[0].content.images.map((partner, ind) => (
                        <div key={ind} className="item">
                            <img src={partner.path} alt={partner.originalname} />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
