import { Link, useLocation } from 'react-router-dom';
import Modal from 'react-modal';

const customStyles = {
    content: {
        height: 'auto ',
    },
};

export const DiznatusVartistModal = ({ isOpen, closeModal }) => {
    const { pathname } = useLocation();
    const Language = (ua, ru) => (pathname === '/' ? ua : ru);

    if (!isOpen) return null;
    return (
        <Modal isOpen={isOpen} onRequestClose={closeModal} style={customStyles}>
            <div className="callback-popup popup jqm-init open">
                <div className="wrap">
                    <div className="head">
                        <div className="title">
                            <span>
                                {Language(
                                    'Яким месенджером користуєтесь?',
                                    'Каким мессенджером пользуетесь?',
                                )}
                            </span>
                            <span className="text_3">
                                {Language(
                                    'Виберіть месенджер та дізнайтеся вартість потрібного виробу',
                                    'Выберите мессенджер и узнайте стоимость нужного изделия',
                                )}
                            </span>
                        </div>
                        <Link onClick={closeModal} className="close"></Link>
                    </div>

                    <div className="bot_modal">
                        <a
                            class="btn color submit price_btn"
                            href="tg://resolve?domain=maysternya_zhalyuzi_bot">
                            TELEGRAM BOT
                        </a>
                        <a
                            class="btn color submit price_btn"
                            href="viber://pa?chatURI=maysternya_zhalyuzi">
                            VIBER BOT
                        </a>
                    </div>
                </div>
            </div>
        </Modal>
    );
};
