import { useLocation } from 'react-router-dom';
import { useState } from 'react';
export const Stages = ({ STAGES, STAGES_NO_INSTALL, STAGES_INSTALL }) => {
    const { pathname } = useLocation();
    const Language = (ua, ru) => (pathname === '/' ? ua : ru);
    const findFielStages = (fieldId) => STAGES?.fields.find((el) => el._id === fieldId);

    const [isButtonInstall, setButtonInstall] = useState('no-install');

    const TITLE = findFielStages('652cf45bba63b8929f4c1b93');
    const DESCRIPTION = findFielStages('652cf479ba63b8929f4c1b95');
    const BUTTON_NO_INSTALL = findFielStages('652cf4adba63b8929f4c1b97');
    const BUTTON_INSTALL = findFielStages('652cf4bbba63b8929f4c1b99');

    const activItems =
        isButtonInstall === 'no-install' ? STAGES_NO_INSTALL.fields : STAGES_INSTALL.fields;

    return (
        <section className="stages" id="stages">
            <div className="container">
                <div className="content">
                    <div className="text">
                        <h2 className="subtitle">
                            {Language(TITLE.content.UA.text, TITLE.content.RU.text)}
                        </h2>
                        <div
                            className="desc"
                            dangerouslySetInnerHTML={{
                                __html: Language(
                                    DESCRIPTION?.content.UA.rich_text,
                                    DESCRIPTION?.content.RU.rich_text,
                                ),
                            }}
                        />
                        <div className="stages_btn_box">
                            <button
                                onClick={() => setButtonInstall('no-install')}
                                className={`btn stages_btn ${
                                    isButtonInstall === 'no-install' && 'stages_btn--active'
                                }`}>
                                {Language(
                                    BUTTON_NO_INSTALL.content.UA.title,
                                    BUTTON_NO_INSTALL.content.RU.title,
                                )}
                            </button>
                            <button
                                onClick={() => setButtonInstall('install')}
                                className={`btn stages_btn ${
                                    isButtonInstall === 'install' && 'stages_btn--active'
                                }`}>
                                {Language(
                                    BUTTON_INSTALL.content.UA.title,
                                    BUTTON_INSTALL.content.RU.title,
                                )}
                            </button>
                        </div>
                    </div>

                    <div className="items">
                        {activItems.map((el, ind) => {
                            if (el.field_type === 'Step') {
                                return (
                                    <div key={ind} className="item">
                                        <div className="name">
                                            {Language(el.content.UA.title, el.content.RU.title)}
                                        </div>
                                        <div
                                            className="desc"
                                            dangerouslySetInnerHTML={{
                                                __html: Language(
                                                    el?.content.UA.description,
                                                    el?.content.RU.description,
                                                ),
                                            }}
                                        />
                                    </div>
                                );
                            }
                        })}
                    </div>
                </div>
            </div>
        </section>
    );
};
