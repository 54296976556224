import { Link, useLocation } from 'react-router-dom';
import Modal from 'react-modal';
import { useState } from 'react';
import axios from 'axios';
import TextMaskInput from 'react-text-mask';

const customStyles = {
    content: {
        height: 'auto',
    },
};

export const ZamerModal = ({ isOpen, closeModal }) => {
    const { pathname } = useLocation();
    const Language = (ua, ru) => (pathname === '/' ? ua : ru);
    const [isLoading, setLoading] = useState();
    const [errors, setErrors] = useState({});
    const [isSuccesMessage, setSuccesMessage] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
    });

    const validateName = (value) => {
        if (value.trim() === '') {
            return "Це поле є обов'язковим";
        }
        return '';
    };

    const validatePhone = (value) => {
        console.log('value', value);
        const phonePattern = /^\+38\s\d{3}\s\d{3}\s\d{2}\s\d{2}$/;

        if (!phonePattern.test(value)) {
            return 'Введіть правильний номер телефону';
        }
        return '';
    };

    const fetchCreate = async () => {
        setLoading(true);
        console.log('formData', formData);

        // Виконайте валідацію перед відправкою форми
        const newErrors = { ...errors };
        newErrors.name = validateName(formData.name);
        newErrors.phone = validatePhone(formData.phone);

        // Якщо є помилки, не відправляйте форму
        if (newErrors.name || newErrors.phone) {
            setErrors(newErrors);
            setLoading(false);
        } else {
            setErrors(newErrors);
            console.log('Відправленно');
            try {
                const { data } = await axios.post(
                    'https://backend.server-zanaves.com.ua/api/order',
                    {
                        fullName: formData.name,
                        phone: formData.phone,
                        service: 'Запис на замір',
                        whence: 'landing',
                        status: '650ae621217b8af46970f7cf', // Новий
                    },
                );

                if (data.success) {
                    setLoading(false);
                    setSuccesMessage(true);
                    setFormData({
                        name: '',
                        phone: '',
                    });
                } else {
                    setLoading(false);
                }
            } catch (e) {
                setLoading(false);
                return;
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        setErrors({});
    };

    const onClose = () => {
        closeModal();
        setSuccesMessage(false);
        setFormData({
            name: '',
            phone: '',
        });
        setErrors({});
    };

    if (!isOpen) return null;
    return (
        <Modal isOpen={isOpen} onRequestClose={onClose} style={customStyles}>
            <div className="callback-popup popup jqm-init open">
                <div className="wrap">
                    <div className="head">
                        <div className="title">
                            {!isSuccesMessage ? (
                                <>
                                    <span className="text_1">
                                        {Language(
                                            'Замірник зібрав усе, що потрібно для виміру',
                                            'Замерщик собрал все, что нужно для измерения',
                                        )}
                                    </span>
                                    <span>{Language('Куди їхати?', 'Куда ехать?')} </span>
                                    <span className="text_3">
                                        {Language(
                                            'Залишіть контакти. Менеджер передзвонить, уточнить зручний час для виміру та запише адресу об’єкту.',
                                            'Оставьте контакты. Менеджер перезвонит, уточнит удобное время измерения и запишет адрес объекта.',
                                        )}
                                    </span>
                                </>
                            ) : (
                                <span>{Language('Дякуємо', 'Спасибо')} </span>
                            )}
                        </div>
                        <Link onClick={onClose} className="close"></Link>
                    </div>

                    <div className="form form-popup">
                        {!isSuccesMessage ? (
                            <>
                                <div className="fields">
                                    <div className="field text">
                                        <div className="label">
                                            {Language("Ваше ім'я", 'Ваше имя')}
                                            <sup>*</sup>
                                        </div>
                                        <input
                                            type="text"
                                            name="name"
                                            required="required"
                                            style={{ borderColor: errors.name && '#ff0000' }}
                                            value={formData.name}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="field text">
                                        <div className="label">
                                            {Language('Ваш номер телефону', 'Ваш номер телефона')}
                                            <sup>*</sup>
                                        </div>
                                        <TextMaskInput
                                            type="tel"
                                            name="phone"
                                            value={formData.phone}
                                            style={{ borderColor: errors.phone && '#ff0000' }}
                                            onChange={handleInputChange}
                                            mask={[
                                                '+',
                                                '3',
                                                '8',
                                                ' ',
                                                /[0-9]/,
                                                /[0-9]/,
                                                /[0-9]/,
                                                ' ',
                                                /[0-9]/,
                                                /[0-9]/,
                                                /[0-9]/,
                                                ' ',
                                                /[0-9]/,
                                                /[0-9]/,
                                                ' ',
                                                /[0-9]/,
                                                /[0-9]/,
                                            ]}
                                        />
                                    </div>
                                </div>

                                <button
                                    onClick={fetchCreate}
                                    type="submit"
                                    disabled={isLoading}
                                    className="btn color submit">
                                    {isLoading ? 'Loading...' : Language('Відправити', 'Отправить')}
                                </button>
                            </>
                        ) : (
                            <div className="content">
                                <div className="icon icon-succees"></div>
                                <div className="text">
                                    {Language(
                                        "Наш менеджер скоро з вами зв'яжеться",
                                        'Наш менеджер скоро с вами свяжется',
                                    )}
                                </div>
                                <Link onClick={onClose} className="btn color">
                                    {Language('Закрити', 'Закрыть')}
                                </Link>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Modal>
    );
};
